<template>
  <a-modal
    title="添加"
    :width="1680"
    :visible="visible"
    @ok="modalok"
    @cancel="modalcancel"
  >
    <div>
      <a-row>
        <a-col span="11">
          <div>
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-row :gutter="4">
                  <a-col span="6">
                    <a-form-item label="名称">
                      <a-input v-model="queryParam.name" placeholder="请输入" allowClear/>
                    </a-form-item>
                  </a-col>
                  <a-col span="6">
                    <a-form-item label="位置">
                      <a-select default-value="" style="width: 100%" v-model="queryParam.location" allowClear placeholder="请选择">
                        <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col span="6">
                    <a-form-item label="资产类型">
                      <a-select default-value="" style="width: 100%" v-model="queryParam.property_type" allowClear placeholder="请选择" >
                        <a-select-option v-for="(val, key) in this.$Dictionaries.property_type" :value="key" :key="key">
                          {{ val }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col span="6">
                    <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <s-table
              :customRow="itemClick"
              ref="table"
              :showSizeChanger="false"
              :pageSize="5"
              size="small"
              rowKey="id"
              :loading="loading"
              :columns="columns"
              :data="loadData"
            >
              <template #title>第一步</template>
            </s-table>
          </div>
        </a-col>
        <a-col span="2" style="height: 40vh; text-align: center">
          <a-divider type="vertical" style="height: 100%" />
        </a-col>
        <a-col span="11">
          <div>
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-row :gutter="4">
                  <a-col span="6">
                    <a-form-item label="名称">
                      <a-input v-model="queryCellParam.name" placeholder="请输入" allowClear/>
                    </a-form-item>
                  </a-col>
                  <a-col span="6">
                    <a-button type="primary" @click="getZonePropertyCellsList(-1)">查询</a-button>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <a-table
              ref="table2"
              size="small"
              rowKey="id"
              :pagination="{ pageSize: 5, defaultPageSize: 5,current:this.current,total: this.propertyCellTotal,showSizeChanger: false }"
              @change="handleTableChange"
              :loading="cell_loading"
              :customRow="itemCellClick"
              :columns="columns_cell"
              :dataSource="zonePropertyCellsList"
            >
              <template #title>第二步</template>
            </a-table>
          </div>
        </a-col>
      </a-row>
      <a-divider orientation="left">选中资产展示</a-divider>
      <a-form :form="form" v-bind="formLayout">
        <a-row>
          <a-col :span="12">
            <a-form-item label="cellId" v-show="false">
              <a-input
                v-decorator="['zone_property_cell_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              />
            </a-form-item>
            <a-form-item label="名称">
              <a-input
                disabled
                v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
              />
            </a-form-item>
            <a-form-item label="面积">
              <a-input-number
                :min="0"
                v-decorator="['used', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
              <span v-if="max_area !== 0" style="margin-left: 10px">可用面积：{{ max_area }}</span>
            </a-form-item>
            <a-form-item label="所有人" v-if="false">
              <a-input
                v-decorator="['property_owner', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="物业费">
              <a-input-number
                v-decorator="['property_price', {initialValue: '0',rules: [{required: true, message: '必填项，请填写信息'}]}]" />
              <span style="margin-left: 10px"> 元/平米/天</span>
            </a-form-item>
            <a-form-item label="租金">
              <a-input-number
                v-decorator="['rental_price', {initialValue: '0',rules: [{required: true, message: '必填项，请填写信息'}]}]" />
              <span style="margin-left: 10px">元/平米/天</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { STable } from '@/components'
import {
  zone_property_list
} from '@/api/zone_property'
import { Dictionaries } from '@/common/AllConstant'
import { zone_property_cell_list } from '@/api/zone_property_cell'
import pick from 'lodash.pick'
// 表单字段
const fields = ['zone_property_cell_id', 'name', 'property_price', 'rental_price', 'used', 'property_owner']
export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      zonePropertyCellsList: [],
      corList: [],
      form: this.$form.createForm(this),
      cell_loading: false,
      dictionaries: Dictionaries,
      // 查询参数
      propertyData: {},
      propertyCellData: {},
      queryParam: {},
      queryCellParam: {},
      propertyCellTotal: 0,
      current: 1,
      max_area: 0,
      // 表头
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '租金',
          dataIndex: 'standard_price',
          customRender: (text) => (text / 100).toFixed(6),
          ellipsis: true
        },
        {
          title: '物业费',
          dataIndex: 'fee',
          customRender: (text) => (text / 10000).toFixed(4),
          ellipsis: true
        },
        {
          title: '所有人',
          dataIndex: 'property_owner',
          ellipsis: true,
          customRender: (text) => this.$Dictionaries.electricity_meter_property_owner[text]
        }
      ],
      columns_cell: [
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '产权面积',
          dataIndex: 'property_area',
          ellipsis: true
        },
        {
          title: '真实面积',
          dataIndex: 'real_area',
          ellipsis: true
        },
        {
          title: '可用面积',
          dataIndex: 'available_area',
          ellipsis: true
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return zone_property_list(Object.assign(parameter, this.queryParam, { page_size: 5 }))
          .then(res => {
            this.loading = false
            this.cell_loading = false
            return res.data
          })
      }
    }
  },
  created () {
  },
  methods: {
    modalcancel (e) {
      this.$emit('cancel')
    },
    modalok () {
      this.$emit('ok')
    },
    itemClick (record, index) {
      const that = this
      return {
        style: {
          // 字体颜色
          color: record.id === this.propertyData.id ? 'black' : '',
          // 背景颜色
          'background-color': record.id === this.propertyData.id ? 'rgb(0, 180, 237,0.2)' : ''
        },
         on: {
          click: function (event) {
            that.form.resetFields() // 清理表单数据（可不做）
            that.propertyData = record
            that.max_area = 0
            that.getZonePropertyCellsList(record.id)
            console.log(that.propertyData)
          }
        }
      }
    },
    handleTableChange (pagination) {
      console.log(pagination)
      this.current = pagination.current
      this.getZonePropertyCellsList(-1)
    },
    itemCellClick (record, index) {
      const that = this
      return {
        style: {
          // 字体颜色
          color: record.id === this.propertyCellData.id ? 'black' : '',
          // 背景颜色
          'background-color': record.id === this.propertyCellData.id ? 'rgb(0, 180, 237,0.2)' : ''
        },
        on: {
          click: function (event) {
            that.propertyCellData = record
            const property = {
              zone_property_cell_id: record.id,
              rental_price: (that.propertyData.standard_price / 100).toFixed(6),
              property_price: (that.propertyData.fee / 10000).toFixed(4),
              used: record.available_area,
              name: that.propertyData.name + ' / ' + record.name,
              property_owner: that.propertyData.property_owner
            }
            console.log(record)
            that.max_area = record.available_area
            // 防止表单未注册
            fields.forEach(v => that.form.getFieldDecorator(v))
            that.form.setFieldsValue(pick(property, fields))
          }
        }
      }
    },
    getZonePropertyCellsList (id) {
      if (this.propertyData === undefined) {
        return
      }
      if (id === -1) {
        id = this.propertyData.id
      }
      this.cell_loading = true
      zone_property_cell_list(Object.assign(this.queryCellParam, { zone_property_id: id, page_size: 5, page: this.current }))
        .then(({ data }) => {
          this.cell_loading = false
          this.zonePropertyCellsList = data.entries
          this.propertyCellTotal = data.total
          this.current = data.page_number
          return this.zonePropertyCellsList
        })
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
    }
  }
}
</script>
