<template>
  <a-card :bordered="false">
    <div>
      <div class="table-page-search-wrapper">
        <a-spin :spinning="zonePropertyListLoading">
          <a-form :form="form" v-bind="formLayout">
            <a-row :gutter="[16,16]">
              <a-col :span="12">
                <a-form-item label="合同编号">
                  <a-input
                    v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                    :data-source="dataSource"
                    placeholder="请输入"
                  />
                </a-form-item>
                <a-form-item label="合同模版">
                  <a-select
                    option-label-prop="tt"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    show-search
                    allow-clear
                    v-decorator="[
                      'contract_template_id',
                      {
                        rules: [
                          { required: true, message: '' },
                        ],
                      },
                    ]"
                    placeholder="合同模板"
                    :default-active-first-option="false"
                    @search="this.contract_templateSearch"
                    @change="handlecontract_templateChange"
                  >
                    <a-select-option v-for="d in dataSource_contract_template_goods" :key="d.id" :tt="d.name">
                      {{ d.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="企业名称">
                  <a-select
                    option-label-prop="tt"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    show-search
                    allow-clear
                    v-decorator="[
                      'corporation_id',
                      {
                        rules: [
                          { required: true, message: '' },
                        ],
                      },
                    ]"
                    placeholder="请输入公司名称"
                    :default-active-first-option="false"
                    @search="this.corporationSearch"
                    @change="handlecorporationChange"
                  >
                    <a-select-option v-for="d in dataSource" :key="d.id" :tt="d.name">
                      {{ d.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item label="开始日期" extra="格式: YYYY-MM-DD">
                  <a-date-picker
                    v-decorator="['start_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                    :disabled-date="disabledStartDate"
                    show-time
                    format="YYYY-MM-DD"
                    placeholder="开始日期"
                    @openChange="handleStartOpenChange"
                  />
                </a-form-item>
                <a-form-item label="结束日期" extra="格式: YYYY-MM-DD">
                  <a-date-picker
                    v-decorator="['end_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                    :disabled-date="disabledEndDate"
                    show-time
                    format="YYYY-MM-DD"
                    placeholder="结束日期"
                    :open="endOpen"
                    @openChange="handleEndOpenChange"
                  />
                </a-form-item>
                <a-form-item label="押金">
                  <a-input
                    v-decorator="['deposit', {initialValue: '0',rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
                </a-form-item>
                <a-form-item label="年增长百分比">
                  <a-input
                    v-decorator="['year_increase', {initialValue: '0',rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"
                    addon-after="%"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="企业注册地址">
                  <a-textarea
                    rows="2"
                    placeholder="请输入企业注册地址"
                    v-decorator="['address', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
                </a-form-item>
                <a-form-item label="负责人">
                  <a-input
                    v-decorator="['in_charge_person', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"
                    placeholder="请输入负责人" />
                </a-form-item>
                <a-form-item label="甲方联系人">
                  <a-input
                    v-decorator="['a_contact_person', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"
                    placeholder="请输入甲方联系人" />
                </a-form-item>
                <a-form-item label="甲方联系人电话">
                  <a-input
                    v-decorator="['a_contact_phone', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"
                    placeholder="请输入甲方联系人电话" />
                </a-form-item>
                <a-form-item label="乙方联系人">
                  <a-input
                    v-decorator="['contact_person', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"
                    placeholder="请输入乙方联系人" />
                </a-form-item>
                <a-form-item label="乙方联系人电话">
                  <a-input
                    v-decorator="['contact_phone', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"
                    placeholder="请输入乙方联系人电话" />
                </a-form-item>
                <a-form-item label="备注">
                  <a-textarea
                    placeholder="请输入备注"
                    :auto-size="{ minRows: 2, maxRows: 5 }"
                    v-decorator="['remark', {initialValue: '',rules: [{required: false, message: '必填项，请填写信息'}, {max:512, message: '输入超出长度限制'}]}]" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="资产选择" :labelCol="{ span: 2, offset: 1}" :wrapperCol="{span: 19 }">
                  <a-table
                    bordered
                    ref="table"
                    :columns="columns"
                    :data-source="datas"
                    size="small"
                    rowKey="zone_property_cell_id">
                    <template slot="footer">
                      <div style="text-align: center">
                        <a style="width: 180px" type="primary" icon="plus" @click="handleItemAdd">添加资产</a>
                      </div>
                    </template>
                    <span slot="action" slot-scope="text, record">
                      <template>
                        <a-popconfirm
                          title="确定要删除么？"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => deleteItem(record)"
                        >
                          <a>删除</a>
                        </a-popconfirm>
                      </template>
                    </span>
                  </a-table>
                </a-form-item>
                <a-form-item
                  v-bind="buttonCol"
                >
                  <a-row style="margin-top: 50px">
                    <a-col span="8"></a-col>
                    <a-col span="8">
                      <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
                    </a-col>
                    <a-col span="8">
                      <a-button @click="handleGoBack">返回</a-button>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-spin>
      </div>
      <property-list-form
        ref="editPropertyModal"
        v-if="visibleeditProperty"
        :visible="visibleeditProperty"
        :model="mdl"
        @cancel="handleeditPropertyAddCancel"
        @ok="handleeditPropertyOk"
      />
    </div>
  </a-card>
</template>
<script>
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { contract_account, contract_list } from '@/api/contract'
import { corporation_list } from '@/api/corporation'
import { zone_property_cell_list } from '@/api/zone_property_cell'
import { zone_property_list } from '@/api/zone_property'
import PropertyListForm from '@/views/a-rent_contract/modules/PropertyListForm'
import moment from 'moment'
import { rent_contract_create } from '@/api/rent_contract'
// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']

const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: '租金',
    ellipsis: true,
    dataIndex: 'rental_price'
  },
  {
    title: '物业费',
    ellipsis: true,
    dataIndex: 'property_price'
  },
  {
    title: '面积',
    ellipsis: true,
    dataIndex: 'used'
  },
  {
    width: 100,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  components: {
    PropertyListForm
  },
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    /* this.formLayout = {
      labelCol: {
        xs: { span: 3 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    } */
    this.contract_templateSearch = debounce(this.handlecontract_templateSearch, 800)
    this.corporationSearch = debounce(this.handlecorporationSearch, 800)
    return {
      dataSource_contract_template_goods: [],
      formLayout: {
        labelCol: {
          xs: { span: 3 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      fieldName: {
        value: 'id',
        label: 'name',
        children: 'children'
      },
      visibleeditProperty: false,
      endOpen: false,
      zonePropertyListLoading: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      contractList: [],
      zonePropertyList: [],
      zonePropertyCellsList: [],
      zujinList: [],
      tip: '',
      dataSource: [],
      content: '',
      selectedItemId: -1,
      selectedItem: {},
      selectedCellItem: {},
      targetOption: {},
      mdl: {},
      columns,
      datas: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.getcontract()
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })

    this.handlecontract_templateSearch(undefined)
    this.handlecorporationSearch(undefined)
  },
  mounted () {
    this.getzujincontract()
    this.getZonePropertyList()
  },
  methods: {
    // 合同
    handlecontract_templateSearch (value) {
      contract_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_contract_template_goods = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handlecontract_templateChange (value) {
      if (value === undefined) {
        this.handlecontract_templateSearch(value)
      }
    },
    // 公司
    handlecorporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        // this.dataSource = !value ? [] : result
        this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handlecorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handlecorporationSearch(value)
      }
    },
    editItem (item) {
      this.mdl = item
    },
    deleteItem (value) {
      const index = this.datas.indexOf(value)
      this.datas.splice(index, 1)
    },
    handleItemAdd () {
      this.visibleeditProperty = true
    },
    zonePropertySelect (value) {
      this.selectedItemId = value
      this.selectedItem = this.zonePropertyList.find(this.findZoneProperty)
      console.log(find)
      this.getZonePropertyCellsList(value)
    },
    findZoneProperty (item) {
      return item.id === this.selectedItemId
    },
    findZonePropertyCells (item) {
      return item.id === this.selectedCellItem
    },
    loadData (selectedOptions) {
      this.targetOption = selectedOptions[selectedOptions.length - 1]
      this.selectedItemId = this.targetOption.id
      const number = this.zonePropertyList.findIndex(this.findZoneProperty)
      // this.selectedItem = selectedOptions[0]
      this.targetOption.loading = true

      zone_property_cell_list({ zone_property_id: this.selectedItemId })
        .then(({ data }) => {
          this.targetOption.loading = false
          this.zonePropertyCellsList = data.entries
          this.zonePropertyList[number].children = data.entries
          this.zonePropertyList = [...this.zonePropertyList]
        })
    },
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('end_date')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('start_date')
      console.log(startValue.valueOf())
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    getcontract () {
      contract_list({})
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          this.contractList = data
        })
    },
    getzujincontract () {
      contract_account({})
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          this.zujinList = data
        })
    },
    getZonePropertyList () {
      zone_property_list({ page_size: 1 })
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          this.zonePropertyList = data.entries
          this.zonePropertyList.map(item => {
            item.isLeaf = false
            return item
          })
        })
    },
    getZonePropertyCellsList (id) {
      zone_property_cell_list({ zone_property_id: id })
        .then(({ data }) => {
          this.targetOption.loading = false
          this.zonePropertyCellsList = data.entries
          const number = this.zonePropertyList.findIndex(this.findZoneProperty)
          this.zonePropertyList[number].children = data.entries
          this.zonePropertyList = [...this.zonePropertyList]
        })
    },
    handleSubmit () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          console.log(this.tip)
          if (this.tip !== '') {
            this.$message.warning('未找到您输入的企业')
            return
          }
          if (this.datas.length === 0) {
            this.$message.warning('请先添加资产')
            return
          }
          this.zonePropertyListLoading = true
          console.log('values', values)
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
          // 物业计算使用厘
          // 租金计算使用分
          values.deposit = Math.round(values.deposit * 100)
          const properties = this.datas.map(value => {
            value.rental_price = value.rental_price * 100
            value.property_price = Math.round(value.property_price * 10000)
            return value
          })
          values.properties = properties
          // 新增
          rent_contract_create(values).then(res => {
            this.zonePropertyListLoading = false
            console.log('添加成功------')
            this.$router.push('/rent_contract/index')
          }).finally(() => {
            this.zonePropertyListLoading = false
          })
        } else {
          this.zonePropertyListLoading = false
        }
      })
    },
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleeditPropertyAddCancel () {
      this.visibleeditProperty = false
    },
    handleeditPropertyOk (e) {
      const form = this.$refs.editPropertyModal.form
      this.visibleeditProperty = false
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const index = this.datas.findIndex(value => {
            console.log(value)
            return value.zone_property_cell_id === values.zone_property_cell_id
          })
          if (index > -1) {
            this.$message.warning('已存在')
            return
          }
          if (this.datas.length > 0 && this.datas[0].property_owner !== values.property_owner) {
            this.$message.warning('资产所属不一致')
            return
          }
          console.log('handleeditPropertyOk', values)
          const property = {
            zone_property_cell_id: values.zone_property_cell_id,
            rental_price: values.rental_price,
            property_price: (Math.round(values.property_price * 10000) / 10000).toFixed(4),
            used: values.used,
            name: values.name,
            property_owner: values.property_owner
          }
          this.datas.push(property)
          this.$refs.table.refresh()
        }
      })
    },
    onChange (value) {
      console.log(value)
    }
  }
}
</script>
